import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DAppProvider, MULTICALL_ADDRESSES }  from '@usedapp/core';
import config from './config/config'
/* import { Footer } from './Footer'; */

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={{
      readOnlyChainId: config.chainId,
      readOnlyUrls: {
        [config.chainId]: config.defaultProvider,
      },
      supportedChains: [config.chainId],  
      multicallAddresses: {
        [config.chainId]: config.multicallAddress,
        ...MULTICALL_ADDRESSES
      }
    }}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
