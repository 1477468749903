import { Component } from "react";
import builtball from "../../assets/img/builtball2.png";
import "./BuiltFantom.css";

export class BuiltFantom extends Component {
    render() {
        return (
            <div className="build-fantom">
                <div className="build-fantom-title">
                    <img src={builtball} alt="Built on Fantom" className="build-fantom-logo" />
                    <div className="build-fantom-title-text">Built on Fantom</div>
                </div>
                <div className="build-fantom-description">Fantom is a global, decentralized network with validators and community members from all over planet earth. 
                Fantom has a high-throughput open source smart contract platform for digital assets and dApps. Furthermore, Fantom is able to 
                deliver unparalleled speed, security and reliability. Also, Fantom is highly scalable, being able to process over 
                thousands of transactions per second, and scale to thousands of nodes per second.</div>
            </div>
        );
    }

}