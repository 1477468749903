import { Web3Provider } from '@ethersproject/providers'
import { Signer } from 'ethers';
import config from "../config/config";
import ERC20 from "./ERC20";

export class TombFinance {
    public TOMB: ERC20;
    public FTM: ERC20;
    myAccount: string | undefined;
    signer: Signer | undefined;
    externalTokens: { [name: string]: ERC20 };
  
    constructor(provider: Web3Provider | undefined) {
        this.externalTokens = {};
        for (const [symbol, [address, decimal]] of Object.entries(config.externalTokens)) {
            this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
        }

        this.TOMB = this.externalTokens['TOMB'];
        this.FTM = this.externalTokens['WFTM'];
        this.TOMB.connect(provider?.getSigner())
    }
    /**
     * @param provider From an unlocked wallet. (e.g. Metamask)
     * @param account An address of unlocked wallet account.
     */
    unlockWallet(provider: Web3Provider, account: string) {
        this.signer = provider.getSigner();
        this.myAccount = account;
        const tokens = [this.TOMB];
        for (const token of tokens) {
            token.connect(this.signer);
        }
        console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
    }

    get isUnlocked(): boolean {
        return !!this.myAccount;
    }
}
