import "./ProjectCard.css";
import { Link } from "react-router-dom";

import sun from "../../assets/card/sun.svg";
import moon from "../../assets/card/moon.svg";
import ticket from "../../assets/card/ticket.svg";
import info from "../../assets/card/info.svg";
import clock from "../../assets/card/clock.png";
import thumb from "../../assets/card/Tomb-outline.svg";
import greenTomb from "../../assets/card/green-tomb-outline.png";
import blueTomb from "../../assets/card/blue-tomb-outline.png";
import { useContractCalls } from "@usedapp/core";
import { utils } from "ethers";
import moment from "moment";
import { LaunchStatus } from "../../config/types";
import Utils from "../Utils";

type CardProps = {
  title: string;
  symbol: string;
  seatSaleContractAddress: string;
  seatSaleContractAbi: any[];
  tokenSaleContractAddress: string;
  tokenSaleContractAbi: any[];
  image: string;
};
type Prp = {
  data: CardProps;
  key: Number;
  section: string;
  openModal?: any;
};

function ProjectCard(props: Prp) {
  const seatSaleContractInterface = new utils.Interface(
    JSON.stringify(props.data.seatSaleContractAbi)
  );
  const getTierInformationArgs = {
    address: props.data.seatSaleContractAddress,
    abi: seatSaleContractInterface,
  };
  const [
    seatSaleStartTime,
    seatSaleEndTime
  ] = (
    useContractCalls([
      {
        ...getTierInformationArgs,
        method: "seatSaleStartTime",
        args: [],
      },
      {
        ...getTierInformationArgs,
        method: "seatSaleEndTime",
        args: [],
      },
    ]) ?? []
  ).map((res) => (res ? Number(res) : 0));

  
  const [
    totalSeatsT1, soldSeatsT1,
    totalSeatsT2, soldSeatsT2,
    totalSeatsT3, soldSeatsT3
  ] = (
    useContractCalls([
      {
        ...getTierInformationArgs,
        method: "totalSeats",
        args: [1],
      },
      {
        ...getTierInformationArgs,
        method: "getSoldSeatsPerTier",
        args: [1],
      },
      {
        ...getTierInformationArgs,
        method: "totalSeats",
        args: [2],
      },
      {
        ...getTierInformationArgs,
        method: "getSoldSeatsPerTier",
        args: [2],
      },
      {
        ...getTierInformationArgs,
        method: "totalSeats",
        args: [3],
      },
      {
        ...getTierInformationArgs,
        method: "getSoldSeatsPerTier",
        args: [3],
      },
    ]) ?? []
  ).map(x =>  x ? (Number(x)) : 0);


  const [
    pricePerSeatT1, pricePerSeatT2, pricePerSeatT3
  ] = (
    useContractCalls([
      {
        ...getTierInformationArgs,
        method: "pricePerTierSeat",
        args: [1],
      },
      {
        ...getTierInformationArgs,
        method: "pricePerTierSeat",
        args: [2],
      },
      {
        ...getTierInformationArgs,
        method: "pricePerTierSeat",
        args: [3],
      },
    ]) ?? []
  ).map(x =>  x ? (Number(x)/1e18) : 0);

  function getSeatSaleStatus(): string {
    var currentTimeStamp = moment().unix();
    if (currentTimeStamp < seatSaleStartTime) {
      return LaunchStatus.COMING;
    } else if (
      currentTimeStamp >= seatSaleStartTime &&
      currentTimeStamp <= seatSaleEndTime
    ) {
      return LaunchStatus.LIVE;
    } else {
      return LaunchStatus.FINISHED;
    }
  }

  function getTombTotalAmount() {
      const totalTier1 = totalSeatsT1 * pricePerSeatT1;
      const totalTier2 = totalSeatsT2 * pricePerSeatT2;
      const totalTier3 = totalSeatsT3 * pricePerSeatT3;
    return totalTier1 + totalTier2 + totalTier3;
  }
    function getTombAccumulatedAmount() {
    const totalTier1 = soldSeatsT1 * pricePerSeatT1;
    const totalTier2 = soldSeatsT2 * pricePerSeatT2;
    const totalTier3 = soldSeatsT3 * pricePerSeatT3;
  return totalTier1 + totalTier2 + totalTier3;
}
function getPercentageAccumulated() {
    const percentage = getTombAccumulatedAmount() * 100 / getTombTotalAmount();
    return percentage.toFixed(2) + '%';
}
  getTombTotalAmount();
  function getBarImage() {
    const status = getSeatSaleStatus();
    if (status === LaunchStatus.LIVE) {
      return greenTomb;
    }
    if (status === LaunchStatus.FINISHED) {
      return blueTomb;
    }
    return thumb;
  }

  function getProjectStatusImage() {
    const status = getSeatSaleStatus();
    if (status === LaunchStatus.COMING) {
      return sun;
    }
    if (status === LaunchStatus.LIVE) {
      return clock;
    }

    return moon;
  }
  function getProjectStatusText() {
    const status = getSeatSaleStatus();
    if (status === LaunchStatus.LIVE) {
      return "Live now!";
    }
    if (status === LaunchStatus.COMING) {
      return "Coming soon";
    }

    return "Finished";
  }
  function getProjectStatusClass() {
    const status = getSeatSaleStatus();
    if (status === LaunchStatus.LIVE) {
      return "live-card-status";
    }
    if (status === LaunchStatus.COMING) {
      return "coming-soon-card-status";
    }

    return "finished-card-status";
  }
  function shouldRenderCard(): boolean {
    if (
      getSeatSaleStatus() === LaunchStatus.FINISHED &&
      props.section !== LaunchStatus.FINISHED
    ) {
      return false;
    } else if (
      getSeatSaleStatus() !== LaunchStatus.FINISHED &&
      props.section !== LaunchStatus.FINISHED
    ) {
      return true;
    } else if (
      getSeatSaleStatus() !== LaunchStatus.FINISHED &&
      props.section === LaunchStatus.FINISHED
    ) {
      return false;
    } else if (
      getSeatSaleStatus() === LaunchStatus.FINISHED &&
      props.section === LaunchStatus.FINISHED
    ) {
      return true;
    }
    return false;
  }
  const body = document.body;
  body.classList.add("footer-fix");
  //   var props = this.props;
  return shouldRenderCard() ? (
    <div className="project-card">
      <div className={`${getProjectStatusClass()} card-status-top`}>
        <img src={getProjectStatusImage()} alt="sun" />
        <div className="card-status-text">{getProjectStatusText()}</div>
      </div>

      <div className="boxImage">
        <img className="imgCard" src={props.data.image} alt="" />
      </div>

      <div className="project-card-text">
        <div className="project-card-title">
          {getSeatSaleStatus() === "live" && (
            <div className="card-title live-title">{props.data.title}</div>
          )}
          {getSeatSaleStatus() === "coming" && (
            <div className="card-title coming-title">{props.data.title}</div>
          )}
          {getSeatSaleStatus() === "finished" && (
            <div className="card-title finished-title">{props.data.title}</div>
          )}

          <span className="project-card-subtitle">{props.data.symbol}</span>
        </div>

        <div className="barSection">
          <img className={`imgBar`} src={getBarImage()} alt="" />
          <div className={`textBox`}>
            <span className="text">{getPercentageAccumulated()}</span>
            <div className="bar">
              <div
                className="color initial"
                style={{ width: getPercentageAccumulated() }}
              ></div>
            </div>
            <span className="text">{Utils.roundOff(Number(getTombAccumulatedAmount().toFixed(2)))} / {Utils.roundOff(Number(getTombTotalAmount().toFixed(2)))} $TOMB</span>
          </div>
        </div>
      </div>

      {getSeatSaleStatus() === "live" && (
        <button className="btnBuy">
          <img src={ticket} alt="" />
          <Link to="/buyseat" style={{ textDecoration: "none", color: "#FFF" }}>
            Buy a Seat
          </Link>
          {/* Buy a Seat */}
        </button>
      )}
      {getSeatSaleStatus() === "coming" && (
        <button className="btnBuy more" onClick={props.openModal}>
          <img src={info} alt="" />
          More info
        </button>
      )}
    </div>
  ) : (
    <></>
  );
}
export default ProjectCard;
