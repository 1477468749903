import "./Banner.css";
import imgSeat from '../../assets/img/textBuyaSeat.svg'

function Banner(props: any) {
    return (
        <div className="banner">
            <div className="incubator-text">{props.title}</div> 
            {/* <div className="ftmpad-text">FTMPAD</div> */}
            <div className="ftmpad">
                <img src={props.src} alt="ftmpad" />
            </div>
            
            {props.description === 'BuySeat' &&(
                <img src={imgSeat} alt="" />
            )}
            {props.description === 'Projects' &&(
                <div className="projects-text">View upcoming projects and our past launches</div>
            )}
            {props.description === 'Home' &&(
                <div className="proudly-text">Powered by <div className="proudly-text-sub"><a href="https://tomb.finance">Tomb.finance Group</a></div></div>
            )}
        </div>
    );
}

export default Banner;