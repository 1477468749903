import { Component } from "react";
import interestedcard from "../../assets/img/interestedcard.png";
import tiltedhand from "../../assets/img/tiltedhand.png";
import "./Interested.css";

export class Interested extends Component {
    render() {
        return (
            <div className="interested">
                <div className="interested-card">
                    <img src={interestedcard} alt="interested" />
                </div>
                <div className="interested-details">
                    <div className="interested-title">Interested in our services?</div>
                    <div className="interested-text">Submit your project and receive a response within 24 hours.</div>
                    <div className="interested-buttons">
                        <a href="https://docs.google.com/forms/d/14PuRy--1KyaV7cximzQPAJ8iCrVGw_cooqXQRst4S28/edit" className="interested-apply">Apply now</a>
                        <a href="https://discord.gg/tombfinance" className="interested-contact">Contact us</a>
                    </div>
                </div>
                <div className="interested-hand">
                    <img src={tiltedhand} alt="hand" />
                </div>
            </div>
        );
    }
}