import { Component } from "react";
import { Interested } from "../../components/Interested/Interested";
import policy from "../../assets/img/Policy.png";
import { Redirect } from "react-router-dom";
import termsinactive from "../../assets/img/termsinactive.png";
import eyeactive from "../../assets/img/eyeactive.png";
import "./PrivacyPolicy.css"

type PrivacyState = {
    redirect: boolean;
}
export default class PrivacyPolicy extends Component<{}, PrivacyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false,
        }
    }
    toggleTab() {
        this.setState({redirect: true});
    }
    render() {
        if (this.state.redirect) {
            return (<Redirect to="/terms" />)
        }
        return (
            <div className="privacy-policy">
                <div className="tabs">
                    <div className="boxTabs">
                        <button className='button-tabs' onClick={ () => this.toggleTab() } >
                            <img src={termsinactive} alt="terms" />
                            Terms!
                        </button>
                        <button className='button-tabs active' onClick={ () => this.toggleTab()} >
                            <img src={eyeactive} alt="privacy" />
                            Privacy
                        </button>
                    </div>
                </div>
                <div className="banner">
                    <div className="incubator-text">PRIVACY</div> 
                    <div className="ftmpad" style={{marginLeft: '-150px'}}>
                        <img src={policy} alt="ftmpad" />
                    </div>
                </div>
                <div className="privacy-policy-content">
                    <div>
                        <div>In this document we provide the privacy policy of FTMPad (owner and operator of <a href="https://ftmpad.com">ftmpad.com</a>) </div>
                        <div className="privacy-break">This Privacy Policy describes how <a href="https://ftmpad.com">ftmpad.com</a> will manage end user’s personal information which we collect as a result of your use of <a href="https://ftmpad.com">ftmpad.com</a>, your use of our websites located at <a href="https://ftmpad.com">ftmpad.com</a> and any pages or websites under the 'FTMPad' brand, including any tablet or mobile phone applications that are owned and/or operated by us or whenever we otherwise deal with you. FTMPad is committed to ensuring that the personal information we collect about you is protected and is used, stored and disclosed in accordance with the Privacy Act and this Privacy Policy.</div>
                    </div>
                    <div className="privacy-policy-title">
                        Acceptance of Private Policy and website use
                    </div>
                    <div>
                        <div>By accessing, clicking on, connecting, using or engaging in our services, you agree and confirm that you have consented and agreed to the terms of this Privacy Policy. If you do not agree, either partially or wholly, to this Privacy Policy, you must immediately discontinue accessing, connecting, using, or engaging in our services and on Ftmpad.com.</div>
                        <div>Ftmpad may review the Privacy Policy periodically and reserves the right to change the Privacy Policy at any time at its discretion by posting such changes on the website. Changes will be effective immediately when posted on the website. The end users’ continued use of the website following posting will constitute your acceptance of those changes.
                        This Privacy Policy will not apply to websites which are linked to the website and over which <a href="https://ftmpad.com">ftmpad.com</a> has no control.</div>
                    </div>
                    <div className="privacy-policy-title">
                        Personal Information
                    </div>
                    <div>
                        <div>The kinds of Personal Information that <a href="https://ftmpad.com">ftmpad.com</a> may collect from you is:</div>
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Personally submitted preferences</li>
                            <li>Location data</li>
                            <li>IP address</li>
                        </ul>
                    </div>
                    <div className="privacy-policy-title">
                        Collection Purposes
                    </div>
                    <div>
                        <div><a href="https://ftmpad.com">FTMPAD</a> will collect your Personal Information only by lawful and fair means and not in an intrusive way to operate its service, and to provide you with the following information:</div>

                        <ul>
                            <li>To update you on the technical progress of Ftmpad.</li>
                            <li>To provide services to you as a User.</li>
                            <li>To identify you as a user for security purposes and to comply with our legal obligations.</li>
                            <li>To upgrade and enhance your experience within the website, or to tailor or develop information, services or products to suit your needs which may include market research and conducting promotions.</li>
                            <li>To tell you about our products or services that we think may be of interest to you by communicating with you via email.</li>
                            <li>To create aggregate data about users through demographic profiling, statistical analysis of the database to provide to potential and existing Ftmpad holders, and to allow for more efficient operation of Ftmpad.com’s business.</li>
                            <li>To comply with Ftmpad.com’s legal and statutory obligations.</li>
                            <li>For taking appropriate action if Ftmpad.com has reason to suspect that unlawful activity or misconduct of a serious nature has been, is being or may be engaged in that relates to our functions and activities.</li>
                            <li>To establish, exercise or defend any legal claims.</li>
                            <li>Manage job applications.</li>
                        </ul>

                        <div>You may choose to deal with us on an anonymous basis or to use a pseudonym unless it is not practicable for us to deal with individuals who we cannot identify or we are required by law to only deal with identified individuals. Also, if you do not provide Ftmpad.com with the Personal Information we request, we may be unable to process your request to become a User, provide you with our services or respond to your enquiry or complaint. Examples of Personal Information that may be collected by Ftmpad.com include your name, email address, telephone numbers, your date of birth, other verification information such as your Service usage activities. By becoming a User or otherwise choosing to provide Ftmpad.com with Personal Information you consent to Ftmpad.com collecting, using and disclosing your Personal Information for the above purposes.</div>
                    </div>
                    <div className="privacy-policy-title">
                        Direct Marketing and Opting Out
                    </div>
                    <div>
                        <div>We may use your Personal Information to inform you about FTMPAD's products or services or about promotional activities which Ftmpad.com believes may be of interest or of benefit to you. We may do this via email. If you no longer wish to receive marketing or promotional material from Ftmpad.com at all or in any particular form, you may contact us at any time by email to <a href="mailto:support@ftmpad.com">support@ftmpad.com</a> with your request with which we will comply as soon as is practical.</div>
                        <div>From time to time we may contact you in relation to the management and administration of your <a href="https://ftmpad.com">ftmpad.com</a> account. These communications can be via any of the modes of contact recorded when registering as a User. Such communication does not affect your opt-in or opt-out status for direct marketing communications.</div>
                    </div>
                    <div className="privacy-policy-title">
                        Management of your Personal Information
                    </div>
                    <div>
                        <div>FTMPAD will take all reasonable steps to ensure that the Personal Information which it collects, uses or discloses is correct and is stored in a secure environment which is accessed only by authorised persons.</div>

                        <div className="privacy-break">FTMPAD will destroy or permanently de-identify the Personal Information we hold when it is no longer required for any purpose permitted under the APPs including our legal or operational obligations.</div>
                    </div>
                    <div className="privacy-policy-title">
                        Security of Personal Information
                    </div>
                    <div>
                        <ul>
                            <li>You acknowledge that no data transmission over the Internet is totally secure. Accordingly, <a href="https://ftmpad.com">ftmpad.com</a> does not warrant the security of any information which you transmit to it. You agree and acknowledge that it is impossible to fully guarantee the security of your personal information. Any information which you transmit to Ftmpad.com is transmitted at your own risk. However, once Ftmpad.com receives your transmission, Ftmpad.com will take reasonable steps to protect your Personal Information from misuse, loss and unauthorised access, modification and disclosure. Ftmpad.com’s employees, agents and contractors are required to maintain the confidentiality of Users’ Personal Information and trading behaviour.</li>
                            <li>Information posted or communicated within a social media environment (for example, Facebook, Twitter, Chat Rooms) becomes public information. Ftmpad.com cannot guarantee the security of this type of disclosed information.</li>
                            <li>We take the responsibility very seriously to exclude children from access to our services. We will not accept their information for the purposes of accessing or using the Service. It is however, ultimately the responsibility of parents or guardians to monitor their children’s Internet activities including where appropriate by using Internet screening software.</li>
                            <li>Remember to always log out of your account when you have completed your time on the website. This is particularly important if you share a computer with another person. You are responsible for the security of and access to your own computer, mobile device or any other handset used to access the website.</li>
                            <li>Ultimately, you are solely responsible for maintaining the secrecy of your username, password and any account information. Please be careful whenever using the Internet and our website.</li>
                        </ul>
                    </div>
                    <div className="privacy-policy-title">
                        Sharing your Personal Information
                    </div>
                    <div>
                        We will not rent, sell or disclose your Personal Information to any third parties, except to those who require access to the information to perform their tasks and duties under the binding agreements executed with Us and to share with third parties who have a legitimate purpose for accessing it.
                    </div>
                    <div className="privacy-policy-title">
                        Access to Personal Information
                    </div>
                    <div>
                        <div>You may access the Personal Information collected by <a href="https://ftmpad.com">ftmpad.com</a> by contacting us at <a href="mailto:support@ftmpad.com">support@ftmpad.com</a></div>
                        <div>We will give you access to your Personal Information in the manner requested if that is possible and within a reasonable period. If we refuse your request or cannot give you access in the manner you have requested, we will do what we can to meet your requirements by other means.</div>
                        <div>We may not be required to give you access to your Personal Information in certain circumstances which are set out in the APPs including where it may have an unreasonable impact on other individual's privacy. If we refuse access for such reasons, we will advise you in writing of the refusal and our reasons and the complaint mechanisms available to you.</div>
                    </div>
                    <div className="privacy-policy-title">
                        How to contact Ftmpad
                    </div>
                    <div>
                        <div>If you have any queries regarding this Privacy Policy, your Personal Information collected, requests for access or correction or complaints relating to the handling of your personal information, please contact us by email.</div>

                        <div className="privacy-break">Email: <a href="mailto:support@ftmpad.com">support@ftmpad.com</a></div>
                    </div>
                    <div className="privacy-policy-title">
                        Updates to this Privacy Policy
                    </div>
                    <div>
                        <a href="https://ftmpad.com">Ftmpad.com</a> may review, revise and update this Privacy Policy to reflect our current practices and obligations. We will publish our current Privacy Policy on our website at www.Ftmpad.com and the changes will take effect at the time of publishing. You should review this privacy policy regularly and remain familiar with its terms.
                    </div>
                </div>
                <div className="privacy-policy-interested">
                    <Interested />
                </div>
            </div>
        )
    }
}