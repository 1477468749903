import { Component } from "react";
import logoIcon from "./../../assets/img/logoIcon.svg";
import logoIconOff from "./../../assets/img/logoIconOff.svg";
import menuicon2 from './../../assets/img/menuicon2.png';
import idea from "./../../assets/img/idea.png";
import ticket from "./../../assets/img/ticket.png";
import activeidea from "./../../assets/img/activeidea.png"
import activeticket from "./../../assets/img/activeticket.png";
import analytics from "./../../assets/img/Analytics.png";
import "./Menu.css";
import { RouteComponentProps, withRouter, NavLink, Link } from "react-router-dom";
import ConnectWalletModal from './../WalletModal/ConnectWalletModal'

import { Web3Ethers } from "@usedapp/core";
import wallet from "./../../assets/img/Wallet.png";

import metamaskfox from "./../../assets/img/metamask-fox.png"
import { withDapp } from "../UseDappHOC";
import Utils from "../Utils";

type IState = {
    isOpen: boolean,
}
type IProps = {
    ethers: Web3Ethers,
}
class Menu extends Component<RouteComponentProps<any> & IProps, IState> {
    constructor(props: RouteComponentProps<any> & IProps) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }
    isProjects() {
        return this.props.location.pathname.startsWith("/projects");
    }
    isBuySeat() {
        return this.props.location.pathname.startsWith("/buyseat");
    }
    openModal() {
        this.setState({
            isOpen: true,
        })
    }
    closeModal() {
        this.setState({
            isOpen: false,
        })
    }
    render() {
        const { isOpen } = this.state;
        return (
            <div>
                <ConnectWalletModal 
                    isOpen={isOpen}
                    closeModal={() => this.closeModal()}
                />
                <div className="menu-container">
                    <div className="ftmpad-link">
                        { this.props.location?.pathname === '/' && <Link to="/"><img src={logoIcon} className="menu-icon1" alt="FTMPad" /></Link> }
                        { this.props.location?.pathname !== '/' && <Link to="/"><img src={logoIconOff} className="menu-icon1" alt="FTMPad" /></Link> }
                    </div>
                    <div className="menu-links">
                        <NavLink to="/projects" className="menu-link menu-link-tombfinance" activeClassName="active-link">
                            <img src={this.isProjects() ? activeidea : idea} alt="" />
                            Projects
                        </NavLink>
                        <NavLink to="/buyseat" className="menu-link" activeClassName="active-link">
                            <img src={this.isBuySeat() ? activeticket : ticket} alt="" />
                            Buy a Seat
                        </NavLink>
                        <a href="https://tomb.finance"  className="menu-link" style={{display: 'none'}}>
                            <img src={analytics} alt="" />
                            Analytics
                        </a>
                    </div>
                    <div className="menu-right">
                        {!this.props.ethers.account && 
                            <div onClick={() => this.openModal()} className="menu-connect-wallet">
                                <img src={wallet} alt="connect wallet" />
                                Connect Wallet
                            </div>
                        }
                        {this.props.ethers.account && 
                            <div onClick={() => this.openModal()} className="menu-connected-wallet">
                                <img src={metamaskfox} alt="metamask" />
                                {Utils.snipAccount(this.props.ethers.account)}
                            </div>
                        }
                        <a href="https://ftmscan.com/" target="_blank" rel="noreferrer"><img src={menuicon2} className="menu-icon2" alt="FTMPad" /></a>
                    </div>
                </div>
            </div>
        )
    }
}
export default withDapp(withRouter(Menu));
