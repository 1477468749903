import { Web3Ethers } from "@usedapp/core";
import React from "react";
import close from '../../assets/icon/close.svg'
import ReactModal from "react-modal";
import { withDapp } from "../UseDappHOC";
import terms from "../../assets/img/termsinactive.png";
import metamaskfox from "../../assets/img/metamask-fox.png"
import "./ConnectWalletModal.css";
import Utils from "../Utils";

type ConnectWalletModalProps = {
    ethers: Web3Ethers,
    isOpen: boolean,
    closeModal: () => {},
}
const customStyles = {
    content: {
      background: 'linear-gradient(45.18deg, #7B61FF -0.06%, rgba(100, 57, 221, 0.85) 99.95%)',
      borderRadius: 64,
      border: '1px solid linear-gradient(45.18deg, #7B61FF -0.06%, rgba(100, 57, 221, 0.85) 99.95%)',
      width: '333px',
      margin: '40px auto',
      padding: '30px 18px',
      height: 'max-content',
      fontFamily: '"Poppins", sans-serif',
      inset: '0'
    }
};
const ConnectWalletModal = (props: ConnectWalletModalProps) => (
    <div>
        <ReactModal 
            isOpen={props.isOpen}
            onRequestClose={props.closeModal}
            style={customStyles}
            contentLabel="Connect Wallet Modal"
        >
            <div className="wallet-modal-header">
                <div onClick={props.closeModal} >
                    <img className="close" src={close} alt="" />
                </div>
            </div>
            <div className="wallet-modal-body">
                {!props.ethers.account && 
                    <div className="wallet-main-body">
                        <div className="choose-wallet">Choose Wallet</div>
                        <button className="metamask-button metamask-connect" onClick={() => props.ethers.activateBrowserWallet()}> 
                            <img src={metamaskfox} alt="metamask" />
                            Metamask 
                        </button>
                    </div>
                }
                {props.ethers.account && 
                    <div className="wallet-main-body">
                        <div className="wallet-connected">Wallet Connected</div>
                        <div className="metamask-button metamask-disconnect"> 
                            <img src={metamaskfox} alt="metamask" />
                            {Utils.snipAccount(props.ethers.account)}
                        </div>
                        <div className="disconnect-text" onClick={props.ethers.deactivate}>Disconnect</div>
                    </div>
                }
            </div>
            <div className="wallet-modal-footer">
                <a href="https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-Started-With-MetaMask" target="_blank" rel="noreferrer">
                    <img src={terms} alt="Get Metamask" />How to get Metamask?
                </a>
            </div>
        </ReactModal>
    </div>
);
export default withDapp(ConnectWalletModal);