import { useState } from "react";
import Banner from "../../components/Banner/Banner";
import ModalDetails  from "../../components/ModalDetails/ModalDetails";

import projects from '../../assets/img/Projects.png'
import ido from '../../assets/img/IDO.png'
import cardbg from '../../assets/img/card.svg'
import ghosts from '../../assets/img/projects-ghosts.png'
import othersmoon from '../../assets/img/othersmoon.png'


//assets
import "./Projects.css";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

import ModalData from '../../config/ModalData.json';
import launches from '../../config/projects/launches';

function Projects() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const openModal = (item: any, data:any) => {
        setDataModal({ item: item, data: data });
        setIsOpen(true);
    }

    const closeModal = () => {
        setDataModal({});
        setIsOpen(false);
    }

    return (
        <div className="Projects">
            <ModalDetails 
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                setIsOpen={setIsOpen}
                fullData={dataModal}
            />
            <Banner title="THE BEST" src={projects} description="Projects"/>

            <div className="upcoming-launches">
                <div className="cardbg">
                    <img src={cardbg} style={{height: '423px', width: '308px'}} alt="card"/>
                </div>
                <div className="ido-image"><img src={ido} alt="ido" /></div>
                <div className="upcoming-launches-text">Upcoming Launches</div>
                <div className="upcoming-ghosts">
                    <img src={ghosts} alt="ghosts" />
                </div>
            </div>

            <div className="upcoming-launches-section">
                <div className="upcoming-launches-grid">
                    {
                        launches.map((item, index) => {
                            return <ProjectCard data={item} key={index} section="upcoming" openModal={() => openModal(item, ModalData) }/>
                        })
                    }
                </div>
            </div>
            <div className="finished-launches">
                <img className="othersmoon" src={othersmoon} alt="finished" />
                <div style={{ width: '350px' }}>Finished Launches</div>
            </div>
            <div className="finished-launches-section">
                <div className="finished-launches-grid">
                    {
                        launches.map((item, index) => {
                            return <ProjectCard data={item} section="finished" key={index}/>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Projects;
