import { Component } from "react";
import twitter from "../../assets/img/twitter.png";
import github from "../../assets/img/github.png";
import discord from "../../assets/img/discord.png";
import iconspooky from "../../assets/img/iconspooky.png";
import ftmscan from "../../assets/img/ftmscan.png";
/* import menuicon1 from "../../assets/img/menuicon1.png"; */
import footerIcon from "../../assets/img/footerIcon.svg";
import "./Footer.css";
import { Link } from "react-router-dom";

export class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-logo-copyright">
                    <div className="footer-logo">
                        <Link to="/"><img src={ footerIcon } alt="ftmpad" width="124px" height="46px"/></Link>
                    </div>
                    <div className="footer-copyright">Copyright 2021. All Rights Reserved.</div>
                </div>
                <div className="footer-icons">
                    <a href="https://twitter.com/ftmlaunchpad" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter" /></a>
                    <a href="https://github.com/tombfinance" target="_blank" rel="noreferrer"><img src={github} alt="Github" /></a>
                    <a href="https://discord.gg/tombfinance" target="_blank" rel="noreferrer"><img src={discord} alt="Discord" /></a>
                    <a href="https://spookyswap.finance" target="_blank" rel="noreferrer"><img src={iconspooky} alt="Spooky Swap" /></a>
                    <a href="https://ftmscan.com/" target="_blank" rel="noreferrer"><img src={ftmscan} alt="FTMScan" /></a>
                </div>
                <div className="footer-links">
                    <Link to="/privacy" style={{marginLeft: '0px'}}>Privacy Policy</Link>
                    <Link to="/terms">Terms & conditions</Link>
                    <a href="https://tomb.finance" target="_blank" rel="noreferrer">Tomb.finance</a>
                </div>
            </div>
        );
    }
}