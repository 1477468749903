import React from 'react';
import Modal from 'react-modal';
import './ModalDetails.css';

/* import iconScream from '../../assets/card/icon-scream.svg' */
import close from '../../assets/icon/close.svg'
import telegram from '../../assets/icon/telegram.svg'
import twitter from '../../assets/icon/twitter.svg'

import pool from '../../assets/icon/icon-pool.svg'
import token from '../../assets/icon/icon-token.svg'

const customStyles = {
    content: {
      background: 'linear-gradient(45.18deg, #7B61FF -0.06%, rgba(100, 57, 221, 0.85) 99.95%)',
      borderRadius: 64,
      maxWidth: '800px',
      width: '95%', 
      margin: '40px auto',
      padding: '60px 40px',
      height: 'max-content',
      fontFamily: '"Poppins", sans-serif',
      inset: '0'
    }
};

function ModalDetails(props: any) {
  
    return  (
        <Modal
            isOpen={props.modalIsOpen}
            onRequestClose={props.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="modal-header">
                <div className="modal-title">
                    <img className="img-modal" src={props?.fullData?.item?.image} alt="" />
                    <div className="text">
                        <p className="title">{ (props.fullData?.item?.title) ? props.fullData?.item?.title : '' + (props.fullData?.item?.subtitle) ? (' ' + props.fullData?.item?.subtitle) : '' }</p>
                        <p className="subtitle">{props.fullData?.item?.category}</p>
                    </div>
                </div>
                <div className="modal-close">
                    <p onClick={props.closeModal} >Close </p>
                    <img className="close" src={close} alt="" />
                </div>
            </div>
            <div className="modal-body">
                <div className="social">
                    <a href={props.fullData?.data?.telegram} target="_blank" rel="noreferrer">
                        <img src={telegram} alt="" />
                        Telegram
                    </a>
                    <a href="https://twitter.com/ftmlaunchpad" target="_blank" rel="noreferrer">
                        <img src={twitter} alt="" />
                        Twitter
                    </a>
                </div>
                <div className="form-group">
                    <h3 className="form-group__title">{props.fullData?.data?.title}</h3>
                    <p className="form-group__description">{props.fullData?.data?.description}</p>
                </div>
                <h3 className="titleGroup">Pool detail</h3>
                <div className="form-group">
                    <ul className="list-box">
                        <img className="iconTitleList" src={pool} alt="" />
                        <li className="title-list">
                            <span>
                                Pool Information
                            </span>
                        </li>
                        {
                            props.fullData?.data?.poolInformation.map((item: { title: string, value: string | number}, index: number)  => {
                            return <li key={index}>
                                    <span>
                                        {item.title}
                                    </span>
                                    <span className={`${item.value === 1 ? 'blue': ''}`}>
                                        {item.value === 1 ? 'In Progress' :  item.value}
                                    </span> 
                                </li>
                            })
                        }
                    </ul>
                    <ul className="list-box mt-3">
                        <img className="iconTitleList" src={token} alt="" />
                        <li className="title-list">
                            <span>
                                Token Information
                            </span>
                        </li>
                        {
                            props.fullData?.data?.tokenInformation.map((item: { title: string, value: string | number}, index: number)  => {
                            return <li key={index}>
                                    <span>
                                        {item.title}
                                    </span>
                                    <span>
                                        {item.value}
                                    </span> 
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </Modal>
    )
}

export default ModalDetails;