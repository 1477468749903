import { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './views/Home/Home';
import BuySeat from './views/BuySeat/BuySeat';
import Projects from './views/Projects/Projects';
import { Footer } from './components/Footer/Footer'

import Menu from "./components/Menu/Menu";
import trees from "./assets/img/Trees.png";
import terrain from "./assets/img/terrain.png";
import stars1 from "./assets/img/SmallStars1.png";
import stars2 from "./assets/img/SmallStars2.png";
import bigstars from "./assets/img/BigStars.png";
import moon from "./assets/img/moon.png";
import clouds from "./assets/img/clouds.png";
import bones from "./assets/img/bones.png";
import nubeLeft from './assets/img/nube-left.png'
import nubeRight from './assets/img/nube-right.png'

import './App.css';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import Terms from './views/TermsConditions/Terms';
import ScrollToTop from './components/ScrollToTop';
import NetworkPrompt from './components/NetworkPrompt/useNetworkPromptHOC';

export default class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <img className="nube-left"src={nubeLeft} alt="" />
          <img className="nube-right" src={nubeRight} alt="" />
          <div className="background-1"></div>
          <div className="moon">
            <img src={moon} alt="moon" />
          </div>
          <div className="top-rectangle"></div>
          <div className="small-stars-top">
            <img src={stars1} alt="stars" />
          </div>
          <div className="small-stars-bottom">
            <img src={stars2} alt="stars" />
          </div>
          <div className="clouds">
            <img src={clouds} alt="clouds" />
          </div>
          <div className="terrain">
            <img src={terrain} alt="terrain" />
          </div>
          <div className="bones">
            <img src={bones} alt="bones" />
          </div>
          <div className="trees">
            <img src={trees} alt="trees" />
          </div>

          <NetworkPrompt/>
          <ScrollToTop />
          <Menu />
          <Switch>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy />
            </Route>
            <Route path="/projects">
              <div className="big-stars">
                <img src={bigstars} alt="big stars" />
              </div>
              <Projects />
            </Route>
            <Route path="/buyseat">
              <div className="big-stars">
                <img src={bigstars} alt="big stars" />
              </div>
              <BuySeat />
            </Route>
            <Route path="/">
              <div className="big-stars">
                <img src={bigstars} alt="big stars" />
              </div>
              <Home />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}
