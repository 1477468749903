import { Component } from "react";
import { KeyPoints } from "../KeyPoints/KeyPoints";
import { Interested } from "../Interested/Interested";
import "./BottomSection.css";

export class BottomSection extends Component {
    render() {
        return (
            <div className="bottom-section">
                <KeyPoints />
                <Interested />
            </div>
        );
    }
}