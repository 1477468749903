import { Component } from "react";
import { Redirect } from "react-router-dom";
import conditions from "../../assets/img/Conditions.png";
import { Interested } from "../../components/Interested/Interested";
import termsactive from "../../assets/img/termsactive.png";
import eyeinactive from "../../assets/img/eyeinactive.png";
import "./Terms.css";

type TermsState = {
    redirect: boolean,
}
export default class Terms extends Component<{}, TermsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false,
        }
    }

    toggleTab() {
        this.setState({redirect: true})
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to="/privacy" />)
        }
        return (
            <div className="terms">
                <div className="tabs">
                    <div className="boxTabs">
                        <button className='button-tabs active' onClick={ () => this.toggleTab() } >
                            <img src={termsactive} alt="terms" />
                            Terms!
                        </button>
                        <button className='button-tabs' onClick={ () => this.toggleTab()} >
                            <img src={eyeinactive} alt="privacy" />
                            Privacy
                        </button>
                    </div>
                </div>
                <div className="banner">
                    <div className="incubator-text">TERMS AND</div> 
                    <div className="ftmpad" style={{marginLeft: '-120px'}}>
                        <img src={conditions} alt="ftmpad" />
                    </div>
                </div>
                <div className="terms-content">
                    <div>
                        This end user agreement (the “Agreement”) should be read by you (The “User or “you”) in its entirety prior to your use of Ftmpad’s service and products. Be aware that this Agreement constitutes a legally binding agreement between you and Ftmpad (referred to herein as “Ftmpad”, “us” or “we”) which owns and operates the website on the Internet and the Service at www.ftmpad.com (the “Service”). You consent to adhering to all the terms set forth in this Agreement when/if you are provided with an “I Agree” button and clicking on it.
                    </div>
                    <div>
                        <ul>
                            <li className="spaced-list">You are strongly advised to carefully read these terms as well as the provisions detailed in our Privacy Policy prior to using the website and our Services. By using the website and the Services in any capacity, you agree that;</li>

                            <ol className="ordered-list">
                                <li>You have read and familiarized yourself with these terms.</li>
                                <li>You understand these Terms.</li>
                                <li>You agree to be bound by these Terms when using the website.</li>
                            </ol>

                            <li className="spaced-list">If you do not agree to these Terms, please do not access our website and the Services that come with it.</li>

                            <li className="spaced-list">We reserve the right to modify or amend these Terms, the website or any content on either one of the platforms from time to time, including for security, legal, or regulatory reasons, as well as to reflect updates or changes to the functionality or services of the website. You are advised to check these Terms periodically to ensure that you are cognizant of the current version and comply with them. Users of the website and the Services are bound by these changes which will take immediate effect after the revised versions of these Terms have been published on the website or mobile application. Through your continued use of or interaction with the website, the Services, tools, and information made available on these platforms, you hereby agree to be bound by the provisions highlighted in the subsequent versions. </li>
                        </ul>
                    </div>
                    <div className="terms-content-title">1. The Grant of License</div>
                    <div>
                        <div className="terms-paragraph">This is the grant of a license, not a transfer of title, and under this license, you may not: modify or copy the materials; attempt to decompile or reverse engineer any software contained on the Site; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server. The term "Ftmpad", its domain names and any other trade marks, or service marks used by Ftmpad as part of the Service (the "Trade Marks"), are solely owned by Ftmpad. This license shall automatically terminate if you violate any of these restrictions and may be terminated by Ftmpad at any time.</div>
                        <div className="terms-paragraph">Ftmpad grants the User a non-exclusive, personal, non-transferable right to use the Service on your personal computer or other device that accesses the Internet in order to access and use the services available and described on the Ftmpad.com website (the website and all its functionality together being the "Service"), subject to the terms and conditions contained herein. Ftmpad does not have the ability to verify the legality of the Service in every jurisdiction, therefore it is entirely up to the User to determine whether or not their use of the Service is lawful. Ftmpad reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in and to the Service. You agree that you will be solely liable for any damage, costs or expenses arising out of or in connection with the commission by you of any Unauthorized Use.</div>
                    </div>
                    <div className="terms-content-title">2. No Warranties</div>
                    <div className="terms-paragraph">
                        <div>2.1. Ftmpad disclaims any and all warranties, expressed or implied, in connection with the service which is provided to you "as is" and we provide you with no warranty or representation whatsoever regarding its completeness or accuracy.</div>
                        <div>2.2. We are not responsible for any loss of your digital asset resulting from theft, loss, or mishandling of digital asset private keys outside our control. We are not responsible for any digital asset market and we make no representations or warranties concerning the real or perceived value of digital assets as denominated in any quoted currency.</div>
                        <div>2.3. Regardless of all of Ftmpad's efforts, we make no warranty that the service will be timely or error-free, uninterrupted,or that defects will be corrected.</div>
                    </div>
                    <div className="terms-content-title">3. Terms of Service</div>
                    <div className="terms-paragraph">
                        <div>You agree to all the rules of the Service provided and described on the Ftmpad.com website. Ftmpad retains all authority over the issuing, maintenance, and closing of the Service. </div>
                    </div>
                    <div className="terms-content-title">4. Representations and Warranties</div>
                    <div>
                        <div className="terms-paragraph">
                            You hereby agree to make the following representations and warranties for accessing to the website and Services of Ftmpad:
                        </div>
                        <div className="terms-paragraph">
                            <div>4.1. You have full capacity and authority under the application of laws to agree and bind yourself to these terms;</div>
                            <div>4.2. There is a risk of losing cryptocurrency & other funds of value when using the Service and Ftmpad has no responsibility to you for any such loss.</div>
                            <div>4.3. Your use of the Service is at your sole option, discretion and risk.</div>
                            <div>4.4. You are solely responsible for any applicable taxes which may be payable on cryptocurrency traded or transacted by you through your using the Service;</div>
                            <div>4.5. The telecommunications networks, blockchain networks (such as Ftmpad and Ethereum) and Internet access services required for you to access and use the Service are entirely beyond the control of Ftmpad and Ftmpad shall have no liability whatsoever for any outages, slowness, capacity constraints or other deficiencies affecting the same.</div>
                            <div>4.6  You are eighteen years of age or older.</div>
                            <div>4.7. You are not a citizen or resident from the Prohibited Jurisdictions, and you do not have any relevant connections with any jurisdictions where we have prohibited services and access to the website.</div>
                            <div>4.8. You are aware and agree to comply with all applicable and international laws, statues, ordinances, and regulations applicable to your use of the web and Services. Your use of the website and Services are not for any unlawful or illegal purposes, including but not limited to the usage against the copyright laws, AML/CFT laws.</div>
                            <div>4.9. You warrant that should you discover a bug or error that may lead to an exploit of the Service or other loss of funds from Ftmpad, you have the responsibility to notify Ftmpad at support@ftmpad.com in a timely manner. Furthermore, any attempt by you to use such a discovered exploit or bug for illicit financial gain is illegal and strictly prohibited under this Agreement. Ftmpad reserves the right to prosecute to the fullest extent of the law anyone who exploits or attempts to exploit the Service in an unlawful manner. Such exploits or bugs should be disclosed in a responsible manner and in strict confidence with support@ftmpad.com and no other entity.</div>
                        </div>
                    </div>
                    <div className="terms-content-title">5. Prohibited Uses</div>
                    <div className="terms-paragraph">
                        <div>5.1. The Service is intended solely for the User’s personal use. The User may not create multiple accounts, including for the purpose of collusion and/or abuse of service.</div>
                        <div>5.2. The Service is provided as is, and any exploitation of the Service or errors in program logic and/or code (bugs) constitutes a Prohibited Use and a material breach of this Agreement. Any funds the user may misappropriate in this manner pursuant to this clause shall be considered void and the immediate property of Ftmpad, without limit.</div>
                        <div>5.3. Persons located in any jurisdiction in which it is prohibited from using the Service (the ”Prohibited Jurisdictions”) are not permitted to make use of the Service.</div>
                    </div>
                    <div className="terms-content-title">6. Misuse of the “https://ftmpad.com/” (“the website”)</div>
                    <div className="terms-paragraph">
                        <div>6.1 In the event of any misuse and/or abuse by a user of the website or breach of any provision in these Terms, Ftmpad reserves the right to block your access to the website and others until the matter is solved.</div>
                        <div>6.2 Use of the website for transmission, publication or storage of any material on or via the website which is in violation of any applicable law or regulations or any third-party’s rights is strictly prohibited, including the but not limited to the use of the website or the transmission, distribution, publication or storage of any material on or via the website in a matter or for the purpose which infringes copyright, trademark, trade secret or other intellectual property rights.</div>
                    </div>
                    <div className="terms-content-title">7. Limitations and Liabilities</div>
                    <div className="terms-paragraph">
                        <div>7.1 In no event shall Ftmpad, its affiliates and service providers, or any of their respective officers, directors, agents, employees or representatives be liable for any lost profits or any special, incidental, indirect, intangible, or consequential damages, whether based in contract, tort, negligence, strict liability, or otherwise, arising out of or in connection with authorized or unauthorized use of the site, services, or in the agreement, even if an authorized or unauthorized representative of Ftmpad has been advised of, knew of, or should have known of the possibility of such damages.</div>
                        <div>7.2. Nothing in this Agreement shall exclude or limit Ftmpad’s liability for death or personal injury resulting from its negligence.</div>
                    </div>
                    <div className="terms-content-title">8. Arguments / Disputes / Problems / Discussions</div>
                    <div className="terms-paragraph">
                        If a User wishes to make a complaint, has problems, or start any discussion or questions, please contact Ftmpad's customer service team at support@ftmpad.com. Should any dispute/problem not be resolved to your satisfaction you may pursue remedies in the governing law jurisdiction set forth below.
                    </div>
                    <div className="terms-content-title">9. Governing Law / Jurisdiction</div>
                    <div className="terms-paragraph">
                        The governing law of the arbitration will be the one of the British Virgin Islands.
                    </div>
                    <div className="terms-content-title">10. Severability</div>
                    <div className="terms-paragraph">
                        If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the validity or enforceability in that jurisdiction of any other provision hereof or the validity or enforceability in other jurisdictions of that or any other provision hereof.
                    </div>
                    <div className="terms-content-title">11. Assignment</div>
                    <div className="terms-paragraph">
                        Ftmpad reserves the right to assign this agreement, in whole or in part, at any time without notice. The User may not assign any of his/her rights or obligations under this Agreement.
                    </div>
                    <div className="terms-content-title">12. Miscellaneous / Collection</div>
                    <div className="terms-paragraph">
                        <div>13.1. No waiver by Ftmpad of any breach of any provision of this Agreement shall in any way be construed as a waiver of any subsequent breach of such provision or of any breach of any other provision of this Agreement.</div>
                        <div>13.2 Nothing that has been stated in this Agreement shall create or confer any rights or other benefits in favour of any third parties not party to this Agreement.</div>
                        <div>13.3. Ftmpad may assign, transfer, charge, sub-license, or deal in any other manner with this Agreement, or sub-contract any of its rights and obligations under this Agreement, to any other party.</div>
                    </div>
                    <div className="terms-content-title">13. Risk of loss in value of Cryptocurrency</div>
                    <div className="terms-paragraph">
                        Tokens or any digital currencies are not issued by any central banks or national, supra-national, or quasi-national organizations. Furthermore, they are not backed by any hard assets or other credit. Values of token or digital currencies can fluctuate in value, depending on market conditions and investor behaviour. The investor or user warrants that it is aware of the volatile nature of tokens and digital currencies or cryptocurrencies for that matter, and holds Ftmpad harmless for any loss or damages, which may arise from such high volatility.
                    </div>
                </div>
                <div className="terms-interested">
                    <Interested />
                </div>
            </div>
        )
    }
}