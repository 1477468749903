import { useEffect, useState } from "react";
import Modal from "react-modal";
import "./BuySeat.css";
import "../../App.css";

import { TombFinance } from "../../tombfinance/TombFinance";
import {
  useContractCall,
  useContractCalls,
  useEtherBalance,
  useEthers,
} from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import { constants, utils } from "ethers";
import config from "../../config/config";
import { TAX_OFFICE_V2 } from "../../constants/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { BigNumber } from "@ethersproject/bignumber";

//components
import Banner from "../../components/Banner/Banner";
import ModalDetails from "../../components/ModalDetails/ModalDetails";
import PayAllocationModal from "../../components/ModalTicket/PayAllocationModal";
import ActiveTier from "../../components/ActiveTier/ActiveTier";
import { updateTiersData } from "./config/liveSeats";
//assets
import imgSeat from "../../assets/img/BuyaSeat.svg";
import Card from "../../components/Card/Card";
import BuyCard from "../../components/BuyCard/BuyCard";

import sun from "../../assets/card/sun.svg";
import sunActive from "../../assets/card/sun-active.svg";
import moon from "../../assets/card/moon.svg";
import moonActive from "../../assets/card/moon-active.svg";

import iconScream from "../../assets/card/icon-scream.svg";

import ModalData from "../../config/ModalData.json";
import moment from "moment";

Modal.setAppElement("#modal");
const Round1 = {
  title: "Scream #1",
  subtitle: "",
  category: "$SCREAM",
  live: false,
  color: "blue",
  image: iconScream,
  status: 2,
  statistic: {
    title: "GET 10 TOKENS",
    percent: 95,
    different: "PAY 100 FTM",
  },
  price: 100,
};
const Round2 = {
  title: "Scream #2",
  subtitle: "",
  category: "$SCREAM",
  live: false,
  color: "blue",
  image: iconScream,
  status: 2,
  statistic: {
    title: "GET 10 TOKENS",
    percent: 95,
    different: "PAY 100 FTM",
  },
  price: 100,
};

const seatSaleContractInterface = new utils.Interface(
  JSON.stringify(config.deployments.ScreamSeatSale.abi)
);
const seatSaleContractAddress = config.deployments.ScreamSeatSale.address;

const tokenSaleContractInterface = new utils.Interface(
  JSON.stringify(config.deployments.ScreamTokenSale.abi)
);
const tokenSaleContractAddress = config.deployments.ScreamTokenSale.address;

const tokenLeftOverSaleContractInterface = new utils.Interface(
  JSON.stringify(config.deployments.ScreamLeftOverTokenSale.abi)
);
const tokenLeftOverSaleContractAddress =
  config.deployments.ScreamLeftOverTokenSale.address;

function BuySeat() {
  const [toggleState, setToggleState] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTicketIsOpen, setTicketIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [buySeatInProgress, setBuySeatInProgressStatus] = useState(false);
  const [buyTokensInProgress, setBuyTokensInProgressStatus] = useState(false);
  const [claimTokensInProgress, setClaimTokensInProgressStatus] =
    useState(false);
  const [tombFinance, setTombFinance] = useState<TombFinance>();
  const [tombAllowance, setTombAllowance] = useState(BigNumber.from(0));

  const { account, library } = useEthers();
  const userBalance = useEtherBalance(account) || 0;
  const [isOwned] =
    useContractCall({
      address: seatSaleContractAddress,
      abi: seatSaleContractInterface,
      method: "isSeatOwnedByAddress",
      args: [account],
    }) ?? [];

  if (account && library && !tombFinance) {
    let tF = new TombFinance(library);
    tF.unlockWallet(library, account);
    setTombFinance(tF);
  }

  const getLoader = (content: string) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "10px" }}>{content}</div>
        <ClipLoader loading={true} size={10} />
      </div>
    );
  };

  useEffect(() => {
    async function getAllowance() {
      if (account && tombFinance) {
        let existingAllowance = await tombFinance.TOMB.allowance(
          account,
          TAX_OFFICE_V2
        );
        setTombAllowance(existingAllowance);
      }
    }
    getAllowance();
  }, [account, tombFinance]);

  const approveTomb = async () => {
    try {
      if (tombFinance && tombAllowance.eq(0)) {
        let id = toast(getLoader("Approving TOMB"));
        let tx1 = await tombFinance.TOMB.approve(
          TAX_OFFICE_V2,
          constants.MaxUint256
        );
        await tx1.wait();
        setTombAllowance(constants.MaxUint256);
        toast.update(id, {
          render: "Approved",
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (e) {
      let errorMessage = e.data && e.data.message ? e.data.message : e.message;
      toast.dismiss();
      toast(errorMessage, { type: toast.TYPE.ERROR, autoClose: 5000 });
    }
  };

  const buySeat = async (tierId: number) => {
    let id = toast(getLoader("Checking allowance"), { autoClose: false });
    setBuySeatInProgressStatus(true);
    try {
      if (account && library && !tombAllowance.isZero()) {
        let buySeatContract = new Contract(
          seatSaleContractAddress,
          seatSaleContractInterface,
          library
        );
        let seatSaleContract = buySeatContract.connect(library.getSigner());
        toast.update(id, {
          render: getLoader(`Buying a seat in tier: ${tierId}`),
        });
        let tx = await seatSaleContract.buySeat(tierId);
        await tx.wait();
        toast.update(id, {
          render: "Successful",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        setBuySeatInProgressStatus(false);
      }
    } catch (e) {
      let errorMessage = e.data && e.data.message ? e.data.message : e.message;
      toast.update(id, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      setBuySeatInProgressStatus(false);
    }
  };

  const buyTokens = async (amount: number, address: any, abiInterface: any) => {
    if (amount === 0) {
      return;
    }
    let id = toast(getLoader(`Buying ${amount} tokens.`), {
      autoClose: false,
    });
    setBuyTokensInProgressStatus(true);
    try {
      if (account && library) {
        let tokenSaleContract = new Contract(address, abiInterface, library);
        let signer = tokenSaleContract.connect(library.getSigner());
        const amountBN: any = utils.parseUnits(amount.toString());
        const ONE = utils.parseEther("1.0");
        const tokenPriceBN = utils.parseUnits(
          Number(tokenPrice / 1e18).toString()
        );
        const value = amountBN.mul(tokenPriceBN).div(ONE);
        let tx = await signer.buyTokens(amountBN, { value: value });
        await tx.wait();
        toast.update(id, {
          render: "Successful",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        setBuyTokensInProgressStatus(false);
      }
    } catch (e) {
      let errorMessage = e.data && e.data.message ? e.data.message : e.message;
      toast.update(id, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      setBuyTokensInProgressStatus(false);
    }
  };
  const claimTokens = async (address: any, abiInterface: any) => {
    let id = toast(getLoader(`Claiming tokens.`), {
      autoClose: false,
    });
    setClaimTokensInProgressStatus(true);
    try {
      if (account && library) {
        let tokenSaleContract = new Contract(address, abiInterface, library);
        let signer = tokenSaleContract.connect(library.getSigner());
        let tx = await signer.claimTokens();
        await tx.wait();
        toast.update(id, {
          render: "Successful",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        setClaimTokensInProgressStatus(false);
      }
    } catch (e) {
      let errorMessage = e.data && e.data.message ? e.data.message : e.message;
      toast.update(id, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      setClaimTokensInProgressStatus(false);
    }
  };

  const seatSaleContractArgs = {
    address: seatSaleContractAddress,
    abi: seatSaleContractInterface,
  };

  const [
    totalSeatsT1,
    soldSeatsT1,
    totalSeatsT2,
    soldSeatsT2,
    totalSeatsT3,
    soldSeatsT3,
  ] = (
    useContractCalls([
      {
        ...seatSaleContractArgs,
        method: "totalSeats",
        args: [1],
      },
      {
        ...seatSaleContractArgs,
        method: "getSoldSeatsPerTier",
        args: [1],
      },
      {
        ...seatSaleContractArgs,
        method: "totalSeats",
        args: [2],
      },
      {
        ...seatSaleContractArgs,
        method: "getSoldSeatsPerTier",
        args: [2],
      },
      {
        ...seatSaleContractArgs,
        method: "totalSeats",
        args: [3],
      },
      {
        ...seatSaleContractArgs,
        method: "getSoldSeatsPerTier",
        args: [3],
      },
    ]) ?? []
  ).map((x) => (x ? Number(x) : 0));

  const [seatSaleStartTime, seatSaleEndTime] =
    useContractCalls([
      {
        ...seatSaleContractArgs,
        method: "seatSaleStartTime",
        args: [],
      },
      {
        ...seatSaleContractArgs,
        method: "seatSaleEndTime",
        args: [],
      },
    ]) ?? [];

  const isSeatSaleLive = () => {
    let currentTime = Math.floor(Date.now() / 1000);
    return (
      currentTime >= (seatSaleStartTime || 0) &&
      currentTime <= (seatSaleEndTime || 0)
    );
  };

  function getTierPercent(tier: number) {
    switch (tier) {
      case 1:
        return Number(((soldSeatsT1 * 100) / totalSeatsT1).toFixed(2));
      case 2:
        return Number(((soldSeatsT2 * 100) / totalSeatsT2).toFixed(2));
      case 3:
        return Number(((soldSeatsT3 * 100) / totalSeatsT3).toFixed(2));
    }
    return 0;
  }
  const [tier1PricePerSeat, tier2PricePerSeat, tier3PricePerSeat] = (
    useContractCalls([
      {
        ...seatSaleContractArgs,
        method: "pricePerTierSeat",
        args: [1],
      },
      {
        ...seatSaleContractArgs,
        method: "pricePerTierSeat",
        args: [2],
      },
      {
        ...seatSaleContractArgs,
        method: "pricePerTierSeat",
        args: [3],
      },
    ]) ?? []
  ).map((x) => (x ? Number(x) / 1e18 : 0));

  const [tier1SoldSeats, tier2SoldSeats, tier3SoldSeats] = (
    useContractCalls([
      {
        ...seatSaleContractArgs,
        method: "getSoldSeatsPerTier",
        args: [1],
      },
      {
        ...seatSaleContractArgs,
        method: "getSoldSeatsPerTier",
        args: [2],
      },
      {
        ...seatSaleContractArgs,
        method: "getSoldSeatsPerTier",
        args: [3],
      },
    ]) ?? []
  ).map((x) => (x ? Number(x) : 0));

  const TIERS = updateTiersData(
    tier1PricePerSeat,
    getTierPercent(1),
    tier1SoldSeats,
    totalSeatsT1,
    tier2PricePerSeat,
    getTierPercent(2),
    tier2SoldSeats,
    totalSeatsT2,
    tier3PricePerSeat,
    getTierPercent(3),
    tier3SoldSeats,
    totalSeatsT3
  );

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  const openModal = (item: any, data: any) => {
    setDataModal({ item: item, data: data });
    setIsOpen(true);
  };

  const buyModal = (
    ftmBalance: string,
    tokensLeftToBuy: any,
    modalTokenPrice: any,
    item: any,
    address: any,
    abiInterface: any
  ) => {
    setDataModal({
      ftmBalance: ftmBalance,
      tokensLeftToBuy: tokensLeftToBuy,
      tokenPrice: utils.formatEther(modalTokenPrice),
      item: item,
      address: address,
      abiInterface: abiInterface,
    });
    setTicketIsOpen(true);
  };

  const closeModal = () => {
    setDataModal({});
    setIsOpen(false);
    setTicketIsOpen(false);
  };
  const getLeftOverTokenSaleInformationArgs = {
    address: tokenLeftOverSaleContractAddress,
    abi: tokenLeftOverSaleContractInterface,
  };
  const getTokenSaleInformationArgs = {
    address: tokenSaleContractAddress,
    abi: tokenSaleContractInterface,
  };
  const [saleStartTime, saleEndTime] = (
    useContractCalls([
      {
        ...getTokenSaleInformationArgs,
        method: "saleStartTime",
        args: [],
      },
      {
        ...getTokenSaleInformationArgs,
        method: "saleEndTime",
        args: [],
      },
    ]) ?? []
  ).map((res) => (res ? Number(res) : 0));

  const [leftOverSaleStartTime, leftOverSaleEndTime] = (
    useContractCalls([
      {
        ...getLeftOverTokenSaleInformationArgs,
        method: "saleStartTime",
        args: [],
      },
      {
        ...getLeftOverTokenSaleInformationArgs,
        method: "saleEndTime",
        args: [],
      },
    ]) ?? []
  ).map((res) => (res ? Number(res) : 0));

  function canUserBuyTokens() {
    var currentTimeStamp = moment().unix();
    if (currentTimeStamp < saleStartTime) {
      return false;
    } else if (
      currentTimeStamp >= saleStartTime &&
      currentTimeStamp <= saleEndTime
    ) {
      return true;
    } else {
      return false;
    }
  }
  function canUserBuyTokensRound2() {
    var currentTimeStamp = moment().unix();
    if (currentTimeStamp < leftOverSaleStartTime) {
      return false;
    } else if (
      currentTimeStamp >= leftOverSaleStartTime &&
      currentTimeStamp <= leftOverSaleEndTime
    ) {
      return true;
    } else {
      return false;
    }
  }
  const [tokenPrice] =
    useContractCall({
      address: tokenSaleContractAddress,
      abi: tokenSaleContractInterface,
      method: "pricePerToken",
      args: [],
    }) ?? [];

  const [leftOverTokenPrice] =
    useContractCall({
      address: tokenLeftOverSaleContractAddress,
      abi: tokenLeftOverSaleContractInterface,
      method: "pricePerToken",
      args: [],
    }) ?? [];

  const [getTier] =
    useContractCall({
      address: seatSaleContractAddress,
      abi: seatSaleContractInterface,
      method: "tierPerAddress",
      args: [account],
    }) ?? [];

  const [allocationPerTierSeat, tokensBought] = (
    useContractCalls([
      {
        address: tokenSaleContractAddress,
        abi: tokenSaleContractInterface,
        method: "allocationPerTierSeat",
        args: [getTier],
      },
      {
        address: tokenSaleContractAddress,
        abi: tokenSaleContractInterface,
        method: "tokensBought",
        args: [account],
      },
    ]) ?? []
  ).map((res) => (res ? Number(res) / 1e18 : 0));
  let maxCap: any, leftOverTokensBought: any;
  try {
    [maxCap, leftOverTokensBought] = (
      useContractCalls([
        {
          address: tokenLeftOverSaleContractAddress,
          abi: tokenLeftOverSaleContractInterface,
          method: "maxCap",
          args: [],
        },
        {
          address: tokenLeftOverSaleContractAddress,
          abi: tokenLeftOverSaleContractInterface,
          method: "tokensBought",
          args: [account],
        },
      ]) ?? []
    ).map((res) => (res ? Number(res) / 1e18 : undefined));
  } catch (err) {
    maxCap = undefined;
  }
  function getRemainingTokens() {
    return allocationPerTierSeat - tokensBought;
  }
  function getLeftOverRemainingTokens() {
    return maxCap - leftOverTokensBought;
  }
  return (
    <div className="BuySeat">
      <ModalDetails
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        fullData={dataModal}
      />
      <PayAllocationModal
        modalTicketIsOpen={modalTicketIsOpen}
        closeModal={closeModal}
        setTicketIsOpen={setTicketIsOpen}
        buyTokens={buyTokens}
        fullData={dataModal}
      />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Banner title="Enter and" src={imgSeat} description="BuySeat" />
      <div className="CardSection">
        <ActiveTier
          title="SCREAM IDO"
          image={iconScream}
          openModal={() =>
            openModal({ title: "SCREAM IDO", image: iconScream }, ModalData)
          }
        />
        <div className="grid">
          {TIERS.map((item: any, index: number) => {
            return (
              <Card
                data={item}
                key={index}
                enableApprove={tombAllowance.isZero()}
                approveTomb={() => approveTomb()}
                enableBuy={!isSeatSaleLive() || isOwned || buySeatInProgress}
                buySeat={() => buySeat(item.tier)}
              />
            );
          })}
        </div>
        <h3 className="titleAllocated">Claim/Pay Allocation</h3>
        <div className="tabs">
          <div className="boxTabs">
            <button
              className={
                toggleState === 1 ? "button-tabs active" : "button-tabs"
              }
              onClick={() => toggleTab(1)}
            >
              <img src={toggleState === 1 ? sunActive : sun} alt="" />
              Live now !
            </button>
            <button
              className={
                toggleState === 2 ? "button-tabs active" : "button-tabs"
              }
              onClick={() => toggleTab(2)}
            >
              <img src={toggleState === 2 ? moonActive : moon} alt="" />
              Completed
            </button>
          </div>
        </div>
        <div className="grid">
          {!config.leftOverRound ? (
            <>
              <div />
              {toggleState === 1 && (
                <BuyCard
                  data={Round1}
                  // userCanBuy={canUserBuyTokens()}
                  userCanBuy={
                    canUserBuyTokens() &&
                    isOwned &&
                    !buyTokensInProgress &&
                    !claimTokensInProgress
                  }
                  canClaim={true}
                  tokensBought={tokensBought}
                  totalTokens={allocationPerTierSeat}
                  tokenPrice={tokenPrice}
                  claimTokens={() =>
                    claimTokens(
                      tokenSaleContractAddress,
                      tokenSaleContractInterface
                    )
                  }
                  buyModal={() =>
                    buyModal(
                      utils.formatEther(userBalance),
                      getRemainingTokens(),
                      tokenPrice,
                      Round1,
                      tokenSaleContractAddress,
                      tokenSaleContractInterface
                    )
                  }
                />
              )}
            </>
          ) : (
            <>
              {toggleState === 1 && (
                <BuyCard
                  data={Round1}
                  isOwned={isOwned}
                  userCanBuy={false}
                  tokensBought={tokensBought}
                  totalTokens={allocationPerTierSeat}
                  canClaim={false}
                  tokenPrice={tokenPrice}
                  claimTokens={() =>
                    claimTokens(
                      tokenSaleContractAddress,
                      tokenSaleContractInterface
                    )
                  }
                  buyModal={() =>
                    buyModal(
                      utils.formatEther(userBalance),
                      getRemainingTokens(),
                      tokenPrice,
                      Round1,
                      tokenSaleContractAddress,
                      tokenSaleContractInterface
                    )
                  }
                />
              )}
              {toggleState === 1 && (
                <BuyCard
                  data={Round2}
                  isOwned={isOwned}
                  userCanBuy={
                    canUserBuyTokensRound2() &&
                    isOwned &&
                    !buyTokensInProgress &&
                    !claimTokensInProgress
                  }
                  tokensBought={leftOverTokensBought}
                  totalTokens={maxCap}
                  tokenPrice={leftOverTokenPrice}
                  claimTokens={() =>
                    claimTokens(
                      tokenLeftOverSaleContractAddress,
                      tokenLeftOverSaleContractInterface
                    )
                  }
                  buyModal={() =>
                    buyModal(
                      utils.formatEther(userBalance),
                      getLeftOverRemainingTokens(),
                      leftOverTokenPrice,
                      Round1,
                      tokenLeftOverSaleContractAddress,
                      tokenLeftOverSaleContractInterface
                    )
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BuySeat;
