// import { ChainId } from '@pancakeswap-libs/sdk';
import { Configuration } from './types';

const configurations: { [env: string]: Configuration } = {
  development: {
    leftOverRound: true,
    chainId: 250,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./deployments/deployments.mainnet.json'),
    multicallAddress: '0xb828c456600857abd4ed6c32facc607bd0464f4f',
    externalTokens: {
      WFTM: ['0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18],
      TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18]
    }
  },
  production: {
    leftOverRound: true,
    chainId: 250,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./deployments/deployments.mainnet.json'),
    multicallAddress: '0xb828c456600857abd4ed6c32facc607bd0464f4f',
    externalTokens: {
      WFTM: ['0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18],
      TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18]
    }
  },
};

export default configurations[process.env.NODE_ENV || 'development'];
