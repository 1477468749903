import { Component } from "react";
import "./KeyPoints.css";
import keypoints from "../../assets/img/keypoints.png";

export class KeyPoints extends Component {
    render() {
        return (
            <div className="">
                <div className="key-points-icons">
                    <img src={keypoints} alt="keypoints" />
                </div>
                <div className="key-points">
                    <div className="first-column">
                        <div className="key-point">
                            <div className="key-point-title">Technicals</div>
                            <div className="key-point-description">Technical guidance in general and smart contract reviews.</div>
                        </div>
                        <div className="key-point">
                            <div className="key-point-title">Tokenomics</div>
                            <div className="key-point-description">Assistance on tokenomics design. We will make sure everything is in balance from a utility and value perspective.</div>
                        </div>
                        <div className="key-point">
                            <div className="key-point-title">Tomb Finance investors</div>
                            <div className="key-point-description" style={{marginTop: '33px'}}>Instant exposure to a large user base of Tomb Finance.</div>
                        </div>
                    </div>
                    <div className="second-column">
                        <div className="key-point">
                            <div className="key-point-title">Fine tuning of the project</div>
                            <div className="key-point-description" style={{marginTop: '33px'}}>Prior to launching the project we will assist in fine-tuning the project, so that we make sure the project fits the market from the get go.</div>
                        </div>
                        <div className="key-point">
                            <div className="key-point-title">Initial fund raising</div>
                            <div className="key-point-description">Guidance throughout the seed sale, private sale and public sale. </div>
                        </div>
                        <div className="key-point">
                            <div className="key-point-title">We're here for you</div>
                            <div className="key-point-description">Pre and post-launch project consultancy.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}