import { Component } from "react";
import fairdistribution from "../../assets/img/fairdistribution.png";
import allocations from "../../assets/img/allocations.png";
import "./Accessible.css";

export class Accessible extends Component {
    render() {
        return (
            <div className="accessible-capital">
                <div className="accessible-title">Accessible capital at its finest</div>
                <div className="accessible-illustration">
                    <div className="fair-distribution accessible-section">
                        <img src={fairdistribution} alt="Fair distribution" className="accessible-img"/>
                        <div className="fair-distribution-text accessible-text">
                            <div className="fair-distribution-title accessible-inner-title">
                                Fair distribution
                            </div>
                            <div className="fair-distribution-description accessible-inner-text">
                                Projects launched on Ftmpad are 100% fairly allocated. Allocation is based on X amount of Tomb staked and burned prior to launch. The X numbers of tokens staked per tier will be decided by governance.
                            </div>
                        </div>
                    </div>
                    <div className="allocations accessible-section">
                        <img src={allocations} alt="allocations" className="accessible-img" />
                        <div className="allocations-text accessible-text">
                            <div className="allocations-title accessible-inner-title">
                                Allocations
                            </div>
                            <div className="allocations-description accessible-inner-text">
                                FTMPad will showcase a fixed tier system, where allocations are solely based on the number of tokens staked. Allocation is based on a first come first serve basis, combined with a max. cap per wallet.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}