import imgGhost from "../../../assets/card/img-ghost.svg";
import imgSkel from "../../../assets/card/img-skel.svg";
import imgZombie from "../../../assets/card/img-zombie.svg";
import Utils from "../../../components/Utils";

export const LIVE_TIERS = [
  {
    title: "Tier 1",
    tier: 1,
    subtitle: "seat",
    category: "Total Raised",
    live: false,
    color: "initial",
    image: imgGhost,
    status: 1,
    statistic: {
      title: "0 Tomb",
      percent: 5,
      different: "100% left",
    },
    price: 100,
  },
  {
    title: "Tier 2",
    tier: 2,
    subtitle: "seat",
    category: "Total Raised",
    live: false,
    color: "orange",
    image: imgSkel,
    status: 1,
    statistic: {
      title: "0 Tomb",
      percent: 99,
      different: "100% left",
    },
    price: 300,
  },
  {
    title: "Tier 3",
    tier: 3,
    subtitle: "seat",
    category: "Total Raised",
    live: false,
    color: "blue",
    image: imgZombie,
    status: 1,
    statistic: {
      title: "0 Tomb",
      percent: 100,
      different: "100% left",
    },
    price: 1000,
  },
];

export const updateTiersData = (
  tier1Price: number,
  tier1Percent: number,
  tier1Sold: number,
  totalTier1Seats: number,
  tier2Price: number,
  tier2Percent: number,
  tier2Sold: number,
  totalTier2Seats: number,
  tier3Price: number,
  tier3Percent: number,
  tier3Sold: number,
  totalTier3Seats: number,

) => {
  let tiers = JSON.parse(JSON.stringify(LIVE_TIERS));

  // set tier price
  tiers[0].price = tier1Price;
  tiers[1].price = tier2Price;
  tiers[2].price = tier3Price;

  // set amount of seats per tier
  tiers[0].totalSeats = totalTier1Seats;
  tiers[1].totalSeats = totalTier2Seats;
  tiers[2].totalSeats = totalTier3Seats;

  // set percentage bar
  tiers[0].statistic.percent = tier1Percent;
  tiers[1].statistic.percent = tier2Percent;
  tiers[2].statistic.percent = tier3Percent;

  // set total percentage
  tiers[0].statistic.different =
    tier1Percent === 100 ? "100% completed" : `${100-tier1Percent}% left`;
  tiers[1].statistic.different =
    tier2Percent === 100 ? "100% completed" : `${100-tier2Percent}% left`;
  tiers[2].statistic.different =
    tier3Percent === 100 ? "100% completed" : `${100-tier3Percent}% left`;

  // set total raised
  tiers[0].statistic.title = `${Utils.roundOff(tier1Sold * tier1Price)} TOMB`;
  tiers[1].statistic.title = `${Utils.roundOff(tier2Sold * tier2Price)} TOMB`;
  tiers[2].statistic.title = `${Utils.roundOff(tier3Sold * tier3Price)} TOMB`;

  return tiers;
};
