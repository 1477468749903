import "./BuyCard.css";
//assets
import sunActive from "../../assets/card/sun-active.svg";
import moon from "../../assets/card/moon.svg";
import thumb from "../../assets/card/Tomb-outline.svg";
import thumbClear from "../../assets/card/icon-thomb-outline.svg";
import iconVector from "../../assets/card/icon-vector.svg";

import "./../ModalTicket/PayAllocationModal.css";
import { useEffect, useState } from "react";

function BuyCard(props: any) {
  const [userCanBuy, setUserCanBuy] = useState(props.userCanBuy);
  const [isOwned, setIsOwned] = useState(false);
  useEffect(() => {
    setUserCanBuy(props.userCanBuy);
  }, [props.userCanBuy]);
  const [tokensBought, setTokensBought] = useState(props.tokensBought);
  useEffect(() => {
    setTokensBought(props.tokensBought);
  }, [props.tokensBought]);
  const [maxTokens, setMaxTokens] = useState(props.totalTokens);
  useEffect(() => {
    setMaxTokens(props.totalTokens);
  }, [props.totalTokens]);
  const [tokenPrice, setTokenPrice] = useState(props.tokenPrice/1e18);
  useEffect(() => {
    setTokenPrice(props.tokenPrice/1e18);
  }, [props.tokenPrice]);
  useEffect(() => {
    setIsOwned(props.isOwned);
  }, [props.isOwned]);


  return (
    <div className="Card">
      <div className={`sniped ${props.data.live ? "live" : ""}`}>
        <img src={props.data.live ? sunActive : moon} alt="" />
        <span>{props.data.live ? "Live now!" : "Completed"}</span>
      </div>
      <div className="boxImage">
        <img className="imgCard" src={props.data.image} alt="" />
      </div>

      <div className="cardText">
        <div>
          <h3 className="white">{`${props.data.title}  ${props.data.subtitle}`}</h3>
        </div>

        <span className="cardCategory">{props.data.category}</span>

        <div className="barSection">
          {props.data.live === false && props.data.status === 4 && (
            <img className={`imgBar align }`} src={thumbClear} alt="" />
          )}
          {props.data.live === true && props.data.status !== 4 && (
            <img
              className={`imgBar ${props.data.status !== 1 ? "align" : ""}`}
              src={props.data.status !== 1 ? iconVector : thumb}
              alt=""
            />
          )}
          <div
            className={`textBox ${props.data.color} ${
              props.data.status === 4 ? "clear" : ""
            }`}
          >
            <span className="text">{isOwned === true ? maxTokens-tokensBought : 0} Tokens remaining</span>
            <div className="bar">
              <div
                className={`color ${props.data.color}`}
                style={{ width: (tokensBought)*100/maxTokens + "%" }}
              ></div>
            </div>
            <span className="text">{((isOwned === true ? maxTokens-tokensBought : 0) * tokenPrice).toFixed(2)} FTM</span><br></br>
            <span className="text">{`Bought: ${(tokensBought !== undefined ? tokensBought : 0).toFixed(2)}`}</span>
          </div>
        </div>
        <div className="buttonsContainer">
          <button
            className="buttonBuy buttonBuyElement1"
            disabled={!userCanBuy || tokensBought === maxTokens}
            onClick={props.buyModal}
          >
             Pay
          </button>
          <button className="buttonBuy buttonBuyElement2" onClick={props.claimTokens}>
             Claim
          </button>
        </div>
      </div>
    </div>
  );
}

export default BuyCard;
