import React, {useState, useEffect} from 'react'

import Modal from 'react-modal';
import './PayAllocationModal.css';

/* Assets */

import checkButton from '../../assets/icon/check-button.svg'
import closeButton from '../../assets/icon/close-button.svg'



const customStyles = {
    content: {
      background: 'transparent',
      borderRadius: 0,
      maxWidth: '430px',
      width: '95%', 
      margin: '40px auto',
      padding: '0',
      height: 'max-content',
      fontFamily: '"Poppins", sans-serif',
      inset: '0',
      border: 'none',
      top: '50%',
      transform: 'translateY(50%)',
    }
};

export default function PayAllocationModal(props: any) {
    const [Input, setInput] = useState(0);
    const [ftmBalance, setFtmBalance] = useState(props.fullData.ftmBalance);
    const [tokenPrice, setTokenPrice] = useState(props.fullData.tokenPrice);
    const [amountInFtm, setAmountInFtm] = useState(0.00);
    const [tokensLeftToBuy, setTokensLeftToBuy] = useState(props.fullData.tokensLeftToBuy);

    useEffect(() => {
        setFtmBalance(props.fullData.ftmBalance)
     }, [props.fullData.ftmBalance])

     useEffect(() => {
        setTokensLeftToBuy(props.fullData.tokensLeftToBuy)
     }, [props.fullData.tokensLeftToBuy])
  
     useEffect(() => {
        setTokenPrice(props.fullData.tokenPrice)
     }, [props.fullData.tokenPrice])
  
    const handleChange = (event: any) => {
        let { value } = event.target;
        setInput(value)
        setAmountInFtm(value * tokenPrice)
    }   

    const setMax = () => {
        setInput(tokensLeftToBuy)
    }

    return (
        <Modal
            isOpen={props.modalTicketIsOpen}
            onRequestClose={props.closeModal}
            style={customStyles}
            contentLabel=""
        >
            <div className="bodyModalTicket">
                <div className="titleSection">
                    <h4>{props.fullData.item?.title}</h4>
                    <span>Price per token: {tokenPrice} FTM</span>
                </div>
                <div className="form-group">
                    <input type='number' className="input" value={Input} onChange={(e) => {handleChange(e)}} placeholder=""/>
                    <button className="btnMax" onClick={() => setMax() }>Max</button>
                </div>
                <span className="text">Cost in ftm: {amountInFtm.toFixed(2)}</span><br/>
                <span className="text">Allocation left: {tokensLeftToBuy} SCREAM</span><br/>
                <span className="text">Balance: {Number(ftmBalance)} FTM</span>
            </div>
            <div className="botoneraTicket">
                <button className="btn cancel" onClick={props.closeModal}>
                    <img src={closeButton} alt="" />
                    Cancel
                </button>
                <button className="btn confirm" onClick={() => props.buyTokens(Input, props.fullData.address, props.fullData.abiInterface)}>
                    <img src={checkButton} alt="" />
                    Confirm
                </button>
            </div>
        </Modal>
    )
}

