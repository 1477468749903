import React from 'react'
import  './Card.css'

//assets
import sunActive from '../../assets/card/sun-active.svg'
import moon from '../../assets/card/moon.svg'
import thumb from '../../assets/card/Tomb-outline.svg'
import thumbClear from '../../assets/card/icon-thomb-outline.svg'
import ticket from '../../assets/card/ticket.svg'
import info from '../../assets/card/info.svg'
import dollarPay from '../../assets/icon/dollar-pay.svg'
import dollarBuy from '../../assets/icon/dollar-buy.svg'
import iconVector from '../../assets/card/icon-vector.svg'

function Card(props: any) {
    return (
        <div className="Card">
            <div className={`sniped ${props.data.live ? 'live': ''}`}>
                <img src={props.data.live ? sunActive  : moon } alt="" />
                <span>{props.data.live? 'Live now!': 'Completed'}</span>
            </div>
            <div className="boxImage">
                <img className="imgCard" src={props.data.image} alt="" />
            </div>

            <div className="cardText">
                {
                  props.data.status !== 1 &&
                    <div>
                        <h3 className="white">{props.data.title}</h3>
                        <h3 className="white">{props.data.subtitle}</h3>
                    </div>
                }
                {
                  props.data.status === 1 &&
                    <div>
                        <h3 className={`${props.data.color}`}>{props.data.title}</h3>
                    </div>
                }
               

                <span className="cardCategory">{props.data.category}</span>

                <div className="barSection">
                {
                    props.data.live === false && props.data.status === 4 &&
                    <img className={`imgBar align }`} src={thumbClear} alt="" />
                }
                {
                    props.data.live === true && props.data.status !== 4 &&
                    <img className={`imgBar ${props.data.status !== 1 ? 'align': ''}`} src={props.data.status !== 1 ? iconVector : thumb} alt="" />
                }
                    <div className={`textBox ${props.data.color} ${props.data.status === 4 ? 'clear' : ''}`} >
                        <span className="text">{props.data.statistic.title}</span>
                        <div className="bar">
                            <div className={`color ${props.data.color}`} style={{width: props.data.statistic.percent + '%'}}></div>
                        </div>
                        <span className="text">
                            {props.data.statistic.different}
                        </span>
                    </div>
                </div>
                { props.data.status === 1  && <p className={`price-text ${props.data.color}`}> Total seats: {props.data.totalSeats!}</p> }   
                {
                props.data.status === 1  && 
                <p className={`price-text ${props.data.color}`}> Price per Seat: {props.data.price!} TOMB</p>
                }
                      
                {
                    props.data.live === true && props.data.status === 1 && props.enableApprove && 
                    <button className="btnBuy" disabled={props.enableBuy} onClick={props.approveTomb}>
                        <img src={ticket} alt="" />
                        Approve
                    </button>
                }
                {
                    props.data.live === true && props.data.status === 1 && !props.enableApprove &&
                    <button className="btnBuy" disabled={props.enableBuy} onClick={props.buySeat}>
                        <img src={ticket} alt="" />
                        Buy with Tomb
                    </button>
                }
                {
                    props.data.status && props.data.status === 2 &&
                    <button className="btnBuy pay" onClick={props.buyModal} disabled={props.disableBuyToken}>
                        <img src={dollarPay} alt="" />
                        Pay
                    </button>
                }
                {
                    props.data.status && props.data.status === 3 &&
                    <button className="btnBuy buy">
                        <img src={dollarBuy} alt="" />
                        Buy / Refund
                    </button>
                }
                {
                    props.data.live === false && props.data.status === 4 &&
                    <button className="btnBuy more" onClick={props.openModal}>
                        <img src={info} alt="" />
                        More info
                    </button>
                }
            </div>
        </div>
    )
}

export default Card;

