import iconScream from '../../assets/card/icon-scream.svg';
import config from "../../config/config";
const launches = [
    {
        title: "Scream IDO",
        seatSaleContractAddress: config.deployments.ScreamSeatSale.address,
        seatSaleContractAbi: config.deployments.ScreamSeatSale.abi,
        tokenSaleContractAddress: config.deployments.ScreamTokenSale.address,
        tokenSaleContractAbi: config.deployments.ScreamTokenSale.abi,
        symbol: "$SCREAM",
        image: iconScream,
    },
]

export default launches;