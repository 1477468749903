import iconInfo from '../../assets/icon/info-cyan.svg'
import iconLine from '../../assets/icon/icon-line.svg'

import './ActiveTier.css'

function ActiveTier(props: any) {    
    return (
        <div className="boxDisplay">
        <img className="boxDisplay__line" src={iconLine} alt="" />
        <img className="boxDisplay__img" src={props.image} alt="" />
        <div className="boxDisplay__text">
            <span className="boxDisplay__small">PROJECT COMPLETED!</span>
            <h4 className="boxDisplay__title">{props.title}</h4>
        </div>
        <button className="boxDisplay__button" onClick={props.openModal}>
            <img src={iconInfo} alt="" />
            More info
        </button>
        </div>
    )
}

export default ActiveTier;