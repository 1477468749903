import  Banner  from "../../components/Banner/Banner";
import { FirstFantom } from "../../components/FirstFantom/FirstFantom";
import { BuiltFantom } from "../../components/BuiltFantom/BuiltFantom";
import { Accessible } from '../../components/Accessible/Accessible';
import illustrations2 from "../../assets/img/illustrations2.png";
import cartoons from "../../assets/img/Cartoons.png";
import { Tiers } from '../../components/Tiers/Tiers';
import { Why } from '../../components/Why/Why';
import { BottomSection } from '../../components/BottomSection/BottomSection';
import "./Home.css";

//assets

import imgSeat from '../../assets/img/FTMPAD.png'

function Home() {
    return (
      <div className="Home">
        <Banner 
          title="INCUBATOR"
          description="Home"
          src={imgSeat}
        />
        <FirstFantom />
        <div className="illustration1">
          <img src={illustrations2} alt="illustration" />
        </div>
        <BuiltFantom />
        <Accessible />
        <Tiers />
        <div className="cartoons">
          <img src={cartoons} alt="cartoons" />
        </div>
        <Why />
        <BottomSection />
      </div>
    );
}
  
export default Home;
  