export default class Utils {
    static snipAccount = (account: string) => {
        return account.substr(0,4) + "..." + account.substr(38);
    }
    static roundOff = (amount: number) => {
        if (amount >= 1000) {
            return `${amount/1000}k`;
        }
        return `${amount}`;
    }
}