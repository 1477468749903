import { Component } from "react";
import "./Tiers.css";

export class Tiers extends Component {
    render() {
        return (
            <div className="tiers">
                <div className="tiers-title">The three tiers of Ftmpad</div>
                <div className="tiers-text">Ftmpad uses a tier based system to determine the amount of staked Tomb needed to secure a seat for the allocation.</div>
            </div>
        )
    }

}