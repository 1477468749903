import { Component } from "react";
import card from "../../assets/img/card.svg";
import hand from "../../assets/img/hand.svg";
import ticket from "../../assets/img/blueticket.png";
import ghosts from "../../assets/img/ghosts.png";
import "./FirstFantom.css";

export class FirstFantom extends Component {
    render() {
        return (
            <div className="first-fantom-info">
                <div className="ghosts">
                    <img src={ghosts} alt="ghosts" />
                </div>
                <div className="hand">
                    <img src={hand} alt="hand" />
                </div>
                <div className="card">
                    <img src={card} alt="card" />
                </div>
                <div className="first-fantom-text">
                    <div className="first-text1">The first on</div>
                    <div className="first-text2">FANTOM OPERA</div>
                    <div className="first-text3">Mainnet</div>
                </div>
                <div className="buy-seat">
                    <img src={ticket} alt="ticket" />
                    <a href="https://tomb.finance" className="buy-seat-button">BUY A SEAT</a>
                </div>
                <div className="first-fantom-detail">
                    Here to empower crypto currency projects to raise liquidity, distribute tokens and assist the launching project every step of the way.
                </div>
            </div>       
        )
    };
}