import { Component } from "react";
import "./Why.css";

export class Why extends Component {
    render() {
        return (
            <div className="why">
                <div className="why-title">Why choose us?</div>
                <div className="why-text">
                    <div>Projects that apply for our incubator will go through a screen process. After that, the project that fits us best will be picked, which is decided by our governance system.</div>

                    <div>The chosen project will be assisted by our development team on the following areas:</div>
                </div>
            </div>
        );
    }
}